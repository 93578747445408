import React, { useEffect, useState } from 'react';

// Предположим, что функция для получения токена выглядит так:
const getToken = () => {
    return document.cookie.split('; ').find(row => row.startsWith('access_token='))?.split('=')[1] || localStorage.getItem('access_token');
};

// Пример функции для валидации токена (асинхронный запрос на сервер)
const validateToken = async (token: string): Promise<{ valid: boolean }> => {
    try {
        // Имитируем запрос к серверу, например, через fetch или axios
        const response = await fetch(`/api/validate-token?token=${token}`);
        const data = await response.json();
        return { valid: data.valid };
    } catch (error) {
        console.error('Ошибка валидации токена:', error);
        return { valid: false };
    }
};

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

    const redirectToLogin = () => {
        window.location.href = 'https://auth.qielts.com';
    };

    useEffect(() => {
        const checkAuth = async () => {
            const token = getToken();

            if (!token) {
                redirectToLogin();
                setIsAuthenticated(false);
                return;
            }

            try {
                const { valid } = await validateToken(token);
                if (!valid) {
                    redirectToLogin();
                    setIsAuthenticated(false);
                } else {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                redirectToLogin();
                setIsAuthenticated(false);
            }
        };

        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>; // Пока идёт проверка, показываем загрузку
    }

    return <>{isAuthenticated && children}</>;
};

export default AuthWrapper;
