import React from 'react'

const Home: React.FC = () => {
  return (
    <div>
      <p>Hpme page</p>
    </div>
  )
}

export default Home;









