
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { store } from './store';

import App from './App';

const container = document.getElementById('root');

// async function deferRender() {
//   if (process.env.NODE_ENV !== 'development') {
//     return;
//   }  

//   const {worker} = await import('./mocks/browser');
//   return worker.start();
// }

const root = createRoot(container!);

// deferRender().then(() => {
  root.render(
    <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
    </BrowserRouter>,
  );
// })


