import React, {useState} from 'react';
import {Provider} from 'react-redux';
import {Routes, Route} from 'react-router-dom';

import {store} from './store';

import ClassList from './components/ClassList';
import ClassForm from './components/ClassForm';
import StudentList from './components/StudentList';
import AssignmentList from './components/AssignmentList';
import Header from './components/Header';
import Home from './components/Home';
import {TeachersList} from './components/TeachersList';

import styles from './styles/main.module.css';
import AuthWrapper from "./components/AuthWrapper";

const App: React.FC = () => {

    interface ClassObject {
        id: number;
        name: string;
        studentCount: number;
        teachers: { id: number; name: string }[];
    }

    const [Classes, setClasses] = useState<ClassObject | null>(null);
    const [studentsId, setStudentsId] = useState(0);

    const handleObject = (newObject: ClassObject) => {
        setClasses(newObject);
    };

    const handleStudentId = (id: number) => {
        setStudentsId(id);
    }

    return (
        <AuthWrapper>
            <Provider store={store}>
                <div className={styles.App}>
                    <Header/>
                    <Routes>
                        <Route index element={<Home/>}/>
                        <Route path="classes" element={<ClassList onObject={handleObject}/>}/>
                        <Route path="class/form" element={<ClassForm/>}/>
                        <Route path={`edit/form`} element={<ClassForm classToEdit={Classes ? {
                            id: Classes.id,
                            name: Classes.name,
                            studentCount: Classes.studentCount,
                            teachers: Classes.teachers
                        } : undefined}/>}/>
                        <Route path="teachers" element={<TeachersList/>}/>
                        <Route path="classes/students" element={<StudentList studentId={handleStudentId}/>}/>
                        <Route path="assignment" element={<AssignmentList studentId={studentsId}/>}/>

                    </Routes>
                </div>
            </Provider>
        </AuthWrapper>
    );
};

export default App;
